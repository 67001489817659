<template>
  <component :is="assetData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="assetData === undefined">
      <h4 class="alert-heading">{{ $t("message.errorLoadingData") }}</h4>
      <div class="alert-body">
        {{ $t("message.assets.assetNotFound") }}
        <b-link class="alert-link" :to="{ name: 'apps-asset-list' }">
          {{ $t("message.assets.assetList") }}
        </b-link>
        {{ $t("message.assets.forOtherAssets") }}
      </div>
    </b-alert>

    <template v-if="assetData">
      <div>
        <b-card>
          <h2 class="mb-3">{{ $t("message.assets.editAsset") }}</h2>

          <b-row>
            <b-col md="8">
              <b-form-group label-for="name">
                <template v-slot:label>
                  {{ $t("message.assets.name") }}
                </template>
                <b-form-input
                  v-model="assetData.name"
                  label="name"
                  label-for="nameTasks"
                />
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group label-for="typeAsset">
                <template v-slot:label>
                  {{ $t("message.assets.type") }}
                </template>
                <v-select
                  v-model="assetData.type"
                  :options="typeOptions"
                  :reduce="(val) => val.value"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <!-- text area -->
            <b-col md="12">
              <b-form-group label-for="titleAsset">
                <template v-slot:label>
                  {{ $t("message.assets.desc") }}
                </template>
                <quill-editor
                  id="title"
                  style="margin-bottom: 52px; height: 200px"
                  :options="editorOption"
                  v-model="assetData.description"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="4">
              <b-form-group label-for="quantity">
                <template v-slot:label>
                  {{ $t("message.assets.quantity") }}
                </template>
                <b-form-input
                  v-model="assetData.quantity"
                  label="name"
                  label-for="nameTasks"
                />
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group label-for="value">
                <template v-slot:label>
                  {{ $t("message.assets.valor") }}
                </template>
                <b-form-input
                  v-model="assetData.value"
                  label="value"
                  v-money="getCurrencyFormat()"
                  label-for="nameTasks"
                />
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group label-for="employee">
                <template v-slot:label>
                  {{ $t("message.assets.employee") }}
                </template>
                <v-select
                  v-model="assetData.employeeId"
                  :options="employeeOptions"
                  :reduce="(val) => val.value"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <!-- Spacer -->
          <hr class="invoice-spacing" />

          <b-form-group label-for="wildcard">
            <template v-slot:label> {{ $t("uploadFile") }} </template>
            <b-form-file
              style="width: 350px"
              id="wildcard"
              ref="file-input"
              class="mr-1"
              v-model="file"
              browse-text="Procurar"
              placeholder="Nenhum arquivo selecionado"
              drop-placeholder="Arraste arquivo aqui"
              :accept="fileFormats"
            >
            </b-form-file>
            <b-button
              style="margin-top: 7px"
              variant="warning"
              class="btn-icon mr-1"
              @click="file = null"
              v-if="file"
            >
              {{ $t("clean") }}  <feather-icon icon="RefreshCwIcon" />
            </b-button>
            <b-button
              style="margin-top: 7px"
              variant="success"
              class="btn-icon"
              @click="addFile()"
              v-if="file"
            >
              {{ $t("createFile") }}  <feather-icon icon="SendIcon" />
            </b-button>
          </b-form-group>

          <br />

          <b-row>
            <b-col
              style="max-width: 200px; !important"
              v-for="item in filesData"
              :key="item.id"
            >
              <b-card
                text-variant="white"
                class="text-center"
                bg-variant="primary"
              >
                <feather-icon size="40" icon="FileIcon" />

                <div class="truncate">
                  <br />
                  <span>{{ item.name }}</span>
                </div>
                <br />
                <b-row>
                  <b-col md="6"
                    ><b-button
                      size="sm"
                      @click="downloadFile(item.id, item.name)"
                      v-if="restrictions('asset_file_download')"
                      ><feather-icon size="20" icon="DownloadCloudIcon"
                    /></b-button>
                  </b-col>

                  <b-col md="6"
                    ><b-button
                      size="sm"
                      v-if="restrictions('asset_file_delete')"
                      v-b-modal.modal-delete-file-confirmation
                      @click="
                        selectedFileId = item.id;
                        selectedFileName = item.name;
                      "
                      ><feather-icon size="20" icon="XIcon"
                    /></b-button>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
        </b-card>
        <b-modal
          id="modal-delete-file-confirmation"
          modal-class="modal-danger"
          centered
          title="Deletar"
          hide-footer
        >
          <b-card-text>
            Você tem certeza que deseja deletar o arquivo
            <b>{{ selectedFileName }}</b> ?</b-card-text
          >
          <div class="d-grid gap-2 d-md-flex justify-content-md-end">
            <b-button
              v-b-modal.modal-danger
              class="mt-2"
              variant="danger"
              @click="deleteFile()"
            >
              Deletar
            </b-button>
          </div>
        </b-modal>
      </div>
    </template>

    <div class="text-right">
      <b-button
        @click="updateAssets()"
        variant="primary"
        :to="{ name: 'apps-asset-list' }"
      >
        {{ $t("message.buttons.save") }}
      </b-button>
    </div>
  </component>
</template>

<script>
import {
  BTab,
  BTabs,
  BCard,
  BAlert,
  BLink,
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BCardText,
  BForm,
  BFormSelect,
  BFormTextarea,
  BFormFile,
  BOverlay,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import { ref } from "@vue/composition-api";
import axios from "@axios";
import URL_API from "@core/utils/env";
import getHeader from "@core/utils/auth";
import router from "@/router";
import vSelect from "vue-select";
import dateInputEdit from "@/@core/utils/dateInputEdit";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import store from "@/store";
import currency from "@/@core/utils/currency";
import { VMoney } from "v-money";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import getHeaderFile from "@core/utils/authFile";
import download from "downloadjs";
import AccessControl from "@core/utils/access-control";

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BFormSelect,
    flatPickr,
    BFormTextarea,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    quillEditor,
    BFormFile,
    BOverlay,
    BCardText,
  },

  directives: {
    money: VMoney,
  },

  data() {
    return {
      activeDate: false,
      activeDay: false,

      fileFormats: "",
      fileMaxSize: 0,
      file: null,
      filesData: [],
      selectedFileId: "",
      selectedFileName: "",

      paramData: store.state.user.paramData,

      typeOptions: [
        { label: "Isolado", value: "isolate" },
        { label: "Recorrente", value: "recurrent" },
      ],

      flatConfig: { altFormat: "d/m/Y", altInput: true, format: "d/m/Y" },

      flatConfigDay: {
        dateFormat: "j",
      },

      editorOption: {
        placeholder: "",
        modules: {
          toolbar: [
            ["bold", "italic", "underline"],
            ["code-block"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }],
          ],
          syntax: {
            highlight: (text) => hljs.highlightAuto(text).value,
          },
        },
      },

      userData: store.state.user.userData,

      employeeOptions: [],
      typeOptions: [],
    };
  },

  async created() {
    var employeeList = [];
    var employeeId = [];
    await axios
      .get(`${URL_API}employee/select_list`, {
        headers: getHeader(this.userData),
      })
      .then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          const element = response.data[i].fullName;
          const id = response.data[i].id;
          employeeList.push(element);
          employeeId.push(id);
          this.employeeOptions.push({
            label: employeeList[i],
            value: employeeId[i],
          });
        }
      });

    var typeList = [];
    await axios
      .get(`${URL_API}asset_types`, { headers: getHeader(this.userData) })
      .then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          const element = response.data[i].type;
          typeList.push(element);
          this.typeOptions.push({ label: typeList[i], value: typeList[i] });
        }
      });

    await this.getTaskFilesData();
    this.loadFileFormats();
  },

  watch: {
    "$i18n.locale": function (newVal, oldVal) {
      this.flatConfig = dateInputEdit(newVal);
    },
    file: function (newVal, oldVal) {
      if (newVal != null) {
        const maxAllowedSize = this.fileMaxSize * 1024 * 1024;
        if (newVal.size > maxAllowedSize) {
          // Here you can ask your users to load correct file
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Não permitido",
              icon: "XIcon",
              variant: "danger",
              text: "Arquivo com tamanho maior do que permitido pelo sistema",
            },
          });
          this.$refs["file-input"].reset();
        }
      }
    },
  },

  mounted() {
    if (this.$i18n.locale) {
      this.flatConfig = dateInputEdit(this.$i18n.locale);
    }
  },

  methods: {
    async getTaskFilesData() {
      this.files = [];
      await axios({
        method: "get",
        url: `${URL_API}file/${router.currentRoute.params.id}`,
        headers: getHeader(this.userData),
      }).then((response) => {
        this.filesData = response.data;
      });
    },

    restrictions(value) {
      return AccessControl(value);
    },

    loadFileFormats() {
      this.fileFormats = this.paramData.fileTypes.join(", ").toString();
      this.fileMaxSize = this.paramData.maxFileSize;
    },

    addFile() {
      let formData = new FormData();
      formData.append("file", this.file);

      axios
        .post(
          `${URL_API}file/asset/${this.userData.id}/${router.currentRoute.params.id}`,
          formData,
          {
            headers: getHeaderFile(this.userData),
          }
        )
        .then((response) => {
          if (!response.data.id && response.data != "") {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: this.$i18n.t("noDataAvailable"),
                icon: "XIcon",
                variant: "danger",
                text: this.$i18n.t("noDataAvailableDesc"),
              },
            });
          } else if (response.data === "") {
            this.getTaskFilesData();
            this.file = null;
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: this.$i18n.t("Error"),
                icon: "XIcon",
                variant: "warning",
                text: this.$i18n.t("offline"),
              },
            });
          } else {
            this.getTaskFilesData();
            this.file = null;
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: this.$i18n.t("message.toast.create"),
                icon: "CheckIcon",
                variant: "success",
                text: this.$i18n.t("message.toast.create"),
              },
            });
          }
        });
    },

    deleteFile() {
      axios({
        method: "delete",
        url: `${URL_API}file/${this.selectedFileId}`,
        headers: getHeader(this.userData),
      }).then((response) => {
        if (response.data === "") {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: this.$i18n.t("Error"),
              icon: "XIcon",
              variant: "warning",
              text: this.$i18n.t("offline"),
            },
          });
        } else {
          this.getTaskFilesData();
          this.$root.$emit("bv::hide::modal", "modal-delete-file-confirmation");
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Deletado com sucesso!",
              icon: "CheckIcon",
              variant: "success",
              text: "Arquivo deletado com sucesso",
            },
          });
        }
      });
    },

    downloadFile(id, name) {
      axios({
        method: "get",
        url: `${URL_API}file/download/${id}`,
        headers: getHeader(this.userData),
        responseType: "blob",
      }).then((response) => {
        if (response.data.size === 0) {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: this.$i18n.t("Error"),
              icon: "XIcon",
              variant: "warning",
              text: this.$i18n.t("offline"),
            },
          });
        } else {
          const content = response.headers["content-type"];
          download(response.data, name, content);
        }
      });
    },

    getCurrencyFormat() {
      return currency(this.$i18n.locale);
    },

    switchDotsAndCommas(s) {
      function switcher(match) {
        return match == "," ? "." : ",";
      }
      return s.replaceAll(/\.|\,/g, switcher);
    },

    updateAssets() {
      if (this.assetData.type === "isolate") {
        this.assetData.start = this.assetData.expiryDate;
        this.assetData.end = this.assetData.expiryDate;
      }

      if (this.$i18n.locale === "en") {
        this.assetData.value = Number(
          this.assetData.value.replace(/[^0-9\.-]+/g, "")
        ).toFixed(2);
      } else {
        this.assetData.value = this.switchDotsAndCommas(this.assetData.value);
        this.assetData.value = Number(
          this.assetData.value.replace(/[^0-9\.-]+/g, "")
        ).toFixed(2);
      }

      axios({
        method: "put",
        url: `${URL_API}asset/${this.assetData.id}`,
        headers: getHeader(this.userData),
        data: this.assetData,
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: this.$i18n.t("message.toast.saved"),
            icon: "CheckIcon",
            variant: "success",
            text: this.$i18n.t("message.toast.updatedB"),
          },
        });
      });
    },

    selectedType() {
      if (this.assetData.type === "isolate") {
        this.activeDate = true;
        this.activeDay = false;
      } else {
        this.activeDate = false;
        this.activeDay = true;
      }
    },
  },
  setup() {
    const assetData = ref(null);

    axios({
      method: "get",
      url: `${URL_API}asset/${router.currentRoute.params.id}`,
      headers: getHeader(store.state.user.userData),
    })
      .then((response) => {
        assetData.value = response.data;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          assetData.value = undefined;
        }
      });

    return {
      assetData,
    };
  },
};
</script>

<style>
.mtn {
  margin-top: -0.7rem;
}
</style>
